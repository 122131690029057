import { Cookies } from "react-cookie";

const cookies = new Cookies();

const GetUser = () => {
  let user = cookies.get("user");
  return user;
};

// Set Authentication Token
const SetAccessToken = (accessToken) => {
  return cookies.set("access_token", accessToken);
};

// Set Refresh Token
const SetRefreshToken = (refreshToken) => {
  return cookies.set("azref", refreshToken);
};

// Get Authentication Token
const GetToken = () => {
  return cookies.get("access_token");
};

const GetRefreshToken = () => {
  return cookies.get("azref");
};

// Check if User is Logged In
const LoggedIn = () => {
  return GetRefreshToken() !== undefined;
};

// Get User Email
const GetEmail = () => {
  let user = GetUser();
  if (!user) return " ";
  return user["email"];
};

const ClearAll = () => {
  cookies.remove("access_token");
  cookies.remove("azref");
  cookies.remove("user");
};

const AuthService = {
  GetUser,
  GetToken,
  SetAccessToken,
  GetRefreshToken,
  SetRefreshToken,
  LoggedIn,
  GetEmail,
  ClearAll,
};

export default AuthService;

import { lazy, Suspense } from "react";
import { useCookies } from "react-cookie";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

// Page Imports
import Loader from "components/Loader/Loader";
import Footer from "./components/Layout/Footer/Footer";
import NavBar from "./components/Layout/Header/Header";
import HomePage from "./pages/HomePage/HomePage";
const Checkout = lazy(() => import("./pages/Checkout/Checkout"));
const PremiumPage = lazy(() => import("./pages/PremiumPage/PremiumPage"));
const NotFound = lazy(() => import("./pages/ErrorPages/NotFound"));
const InternalServerError = lazy(() =>
  import("./pages/ErrorPages/InternalServerError")
);
const Results = lazy(() => import("./pages/Results/Results"));
const Faqs = lazy(() => import("./pages/Faqs/Faqs"));

const App = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  setCookie("password", "pwd", { path: "/" });
  setCookie("Name", "name", { path: "/" });

  return (
    <div className="App">
      <NavBar />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          {/* <Route path="/" element={<Navigate to="/dashboard" />} /> */}
          <Route path="/" element={<HomePage />} />
          {/* <Route index path="/home" element={<HomePage />} /> */}
          {/* <Route path="/premium" element={<PremiumPage />} /> */}
          {/* <Route path="/dev" element={<WebDevLandingPage />} /> */}
          {/* <Route path="/ml" element={<MLLandingPage />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/results" element={<Results />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/hr-preparation" element={<HrPage />} />
          <Route path="/webinars" element={<Workshop />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/500" element={<InternalServerError />} />
          <Route path="*" element={<Navigate to="/home" replace />} /> */}
        </Routes>
      </Suspense>
      <Footer />
    </div>
  );
};

export default App;

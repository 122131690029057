import { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import SeoHead from "components/SeoHead/SeoHead";
import { Urls } from "constants/Urls";
import {
  getIsRecentHighlightOpen,
  storeIsRecentHighlightOpen,
} from "services/LocalStorage/RecentHighlightService";
import QueryForm from "../../components/QueryForm/QueryForm";
import VideoModal from "../../components/Video/VideoModal";
import Styles from "./HomePage.module.css";
import RecentHighlight from "./components/RecentHighlight";

const isRecentHighlightOpen = () => {
  const isLastOpen = getIsRecentHighlightOpen();
  storeIsRecentHighlightOpen(true);
  return isLastOpen;
};

const HomePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const newsletter = searchParams.get("newsletter") ?? false;
  const [showPremiumModal, setShowPremiumModal] = useState(
    !isRecentHighlightOpen()
  );

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    if (newsletter) {
      document.getElementById("subscribe-btn").click();
    }
  }, [location]);

  //Modal Video Prop
  const azVideo = (
    <iframe
      title="vimeo-player"
      src="https://player.vimeo.com/video/742647006?h=7f1cb15a1b"
      width="570"
      height="360"
      frameBorder="0"
      allowFullScreen
      loading="lazy"
    ></iframe>
  );

  const mockVideo = (
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/FG9_A5Tecic"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
      loading="lazy"
    ></iframe>
  );

  const [video, setVideo] = useState(azVideo);
  return (
    <>
      {showPremiumModal && (
        <RecentHighlight setShowPremiumModal={setShowPremiumModal} />
      )}
      <SeoHead
        title={"Algozenith | Learn Coding from ICPC World Finalists"}
        description={
          "Embark on a journey where bugs tremble in fear, and code dances to your command. Let's dive into the world of AlgoZenith and unleash your coding superpowers. Are you ready to conquer the realm of algorithms like a true coding warrior? Join us now!"
        }
        keywords={
          "AlgoZenith, coding courses, programming tutorials, competitive programming, data structures, algorithms, coding bootcamp, coding practice, coding interview preparation, online coding platform, programming challenges, coding community, coding mentorship, software engineering, programming skills, coding skills, coding certification, coding education, learn coding, coding for beginners, coding for advanced, programming languages, coding resources, coding exercises, coding problems"
        }
        image={"https://algozenith.com/images/og-image.jpeg"}
      />
      <section>
        <div className="container">
          <div className="row  mt-lg-0">
            <div className="col-12 col-lg-6 ps-md-5 text-center text-lg-start my-auto">
              <p className={Styles.hero_head}>
                <span className={Styles.Awesome_hero_ts}>AlgoZenith</span>
                , <br /> an{" "}
                <span className={Styles.Algo_hero_ts}>Algorithm</span> to become{" "}
                <br />
                <span className={Styles.Awesome_hero_ts}>Awesome</span> at{" "}
                <span className={Styles.Awesome_hero_ts}>DSA</span> and
                <span className={Styles.Awesome_hero_ts}> CP</span>
              </p>
              <img
                src="/img/Home-Page/hero-head.svg"
                className={`img-fluid py-4 ${Styles.hide_lg}`}
                width={400}
                alt=""
              />
              <div className="d-flex justify-content-center justify-content-lg-start pt-1 mb-5 mb-lg-0">
                <Link
                  to="/premium?enroll=true"
                  className={`${Styles.btn_join_now} btn px-md-4`}
                >
                  Join Today
                </Link>
                <button
                  className={`${Styles.Watch_btn} border-0 bg-transparent ms-5`}
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                  onClick={() => setVideo(azVideo)}
                >
                  <img
                    src="/img/Home-Page/video-paly.svg"
                    className="img-fluid me-2"
                    width="40"
                    height="50"
                    alt=""
                  />
                  <span className={Styles.highlight}>Watch Video</span>
                </button>
              </div>
            </div>
            <div
              className={`col-12 col-lg-6 text-center text-lg-end ${Styles.hide_sm} ${Styles.hero_right_bg}`}
            >
              <div className="text-center">
                <img
                  src="/img/Home-Page/hero-head.svg"
                  className="img-fluid py-5"
                  width={400}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Styles.statistics_bg}>
        <div className="container-fluid container-lg py-4">
          <div className="row">
            <div
              className={`col-12 col-md-3 my-auto text-center text-md-start ${Styles.border_right}`}
            >
              <p className={Styles.stats_ts}>
                Our numbers <br /> speak for themselves!
              </p>
            </div>
            <div className="col-4 col-md-3 text-center my-auto">
              <p className={Styles.stats_ts}>2000+</p>
              <p className={Styles.sub_text}>Happy Students</p>
            </div>
            <div className="col-4 col-md-3 text-center my-auto">
              <p className={Styles.stats_ts}>5000+</p>
              <p className={Styles.sub_text}>Doubts resolved</p>
            </div>
            <div className="col-4 col-md-2 text-center my-auto">
              <p className={Styles.stats_ts}>5.0/5</p>
              <p className={`${Styles.sub_text}`}>Rating on Google</p>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-5">
              <p className={Styles.solve_prob_head_ts}>
                We <span className={Styles.solve_prob_span}>Solve</span> all
                your{" "}
                <span
                  className={`${Styles.solve_prob_span} ${Styles.highlight}`}
                >
                  Problems
                </span>
              </p>
            </div>
            <div className="col-12 col-md-6 col-xl-3 px-4 px-md-3">
              <div className={`card ${Styles.problems_card_mod}`}>
                <div className="card-body text-center">
                  <div className={`card ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      {/* <img
                        src="/img/Home-Page/top notch dsa.webp"
                        className="img-fluid"
                        width="200"
                        alt=""
                      /> */}
                      <img
                        src="/img/Home-Page/DSA-Prep.svg"
                        className="img-fluid"
                        width="200"
                        alt=""
                      />
                    </div>
                  </div>
                  <p className={`${Styles.card_head} mt-4`}>
                    Top Notch DSA & CP Prep
                  </p>
                  <p className={`${Styles.card_text} card-text mt-3`}>
                    Stay ahead with top-notch DSA & CP prep, featuring the
                    latest coding trends for interviews.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-0 px-4 px-md-3">
              <div className={`card ${Styles.problems_card_mod}`}>
                <div className="card-body text-center">
                  <div className={`card ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      {/* <img
                        src="/img/Home-Page/resume.webp"
                        className="img-fluid mt-2"
                        width="180"
                        alt=""
                      /> */}
                      <img
                        src="/img/Home-Page/Project- CV.svg"
                        className="img-fluid mt-2"
                        width="150"
                        alt=""
                      />
                    </div>
                  </div>
                  <p className={`${Styles.card_head} mt-4`}>
                    Project for Awesome CV
                  </p>
                  <p className={`${Styles.card_text} card-text mt-3`}>
                    Ease your job search by eliminating resume stress with our
                    project
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-5 mt-xl-0 px-4 px-md-3">
              <div className={`card ${Styles.problems_card_mod}`}>
                <div className="card-body text-center">
                  <div className={`card ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      {/* <img
                        src="/img/Home-Page/mock-interview.webp"
                        className="img-fluid"
                        width="200"
                        alt=""
                      /> */}
                      <img
                        src="/img/Home-Page/Mock-Interview.svg"
                        className="img-fluid"
                        width="130"
                        alt=""
                      />
                    </div>
                  </div>
                  <p className={`${Styles.card_head} mt-4`}>
                    Mock tests and Interview
                  </p>
                  <p className={`${Styles.card_text} card-text mt-3`}>
                    Perfect your skills and temperament with our mock tests and
                    interviews.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-5 mt-md-5 mt-xl-0 px-4 px-md-3">
              <div className={`card ${Styles.problems_card_mod}`}>
                <div className="card-body text-center">
                  <div className={`card ${Styles.inner_card_mod}`}>
                    <div className="card-body">
                      {/* <img
                        src="/img/Home-Page/Refferrals.webp"
                        className="img-fluid mt-3"
                        width="180"
                        alt=""
                      /> */}
                      <img
                        src="/img/Home-Page/Rich-Referrals.svg"
                        className="img-fluid mt-3"
                        width="130"
                        alt=""
                      />
                    </div>
                  </div>
                  <p className={`${Styles.card_head} mt-4`}>
                    Rich Alum base for Referrals
                  </p>
                  <p className={`${Styles.card_text} card-text mt-3`}>
                    Reach out to students who know your worth via the training.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 text-center pt-4 mt-5">
              <div className={`card ${Styles.card_quote_mod}`}>
                <div className="card-body px-5 py-4">
                  <p className={Styles.quote_ts}>
                    " Structured Training + Your Dedication ={" "}
                    <span className={Styles.inner_quote_ts}>
                      Sure shot way of Success
                    </span>{" "}
                    "
                  </p>
                  <p className={Styles.quote_sub_text}>
                    No more hunting Problems & Quality videos in the ocean of
                    internet
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={Styles.course_bg}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 my-auto text-center text-lg-start">
              <p className={`${Styles.course_head} mt-5 mt-lg-0`}>
                Fed up even after putting so much of efforts ??
              </p>
              <img
                src="/img/Home-Page/bored-courses.webp"
                width="200"
                className={`img-fluid ${Styles.hide_lg} py-2`}
                alt=""
              />
              <p className={`${Styles.course_subhead} pb-3`}>
                AlgoZenith Premium trains you like a champion in a out of
                comfort challenging regime, resulting in 10x returns for all
                your efforts !!
              </p>
              <div className="d-flex justify-content-sm-center justify-content-lg-start pt-1 mb-5 mb-lg-0">
                <Link
                  to="/premium?enroll=true"
                  className={`${Styles.btn_join_now} btn px-md-4`}
                >
                  Join Premium Today
                </Link>
                <button
                  className={`${Styles.Watch_btn} border-0 bg-transparent ms-5`}
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                  onClick={() => setVideo(azVideo)}
                >
                  <img
                    src="/img/Home-Page/video-paly.svg"
                    className="img-fluid me-2"
                    width="40"
                    height="50"
                    alt=""
                  />
                  <span className={`${Styles.highlight}`}>Watch Video</span>
                </button>
              </div>
            </div>
            <div className={`col-md-4 text-end ${Styles.hide_sm}`}>
              <img
                src="/img/Home-Page/bored-courses.webp"
                // width="300"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5">
        <div className="container">
          <div className="row mb-md-5">
            <div className="col-12 text-center">
              <p className={Styles.sliding_text}>
                We have got you covered -
                <span
                  className={`${Styles.sliding_text_mod} ${Styles.highlight} `}
                >
                  No matter where you are now !
                </span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 px-lg-5 mt-5 mt-md-0">
              <div className={`card ${Styles.card_mod_features}`}>
                <div className="card-body py-4">
                  <div className="row px-2">
                    <div className="col-3 my-auto">
                      <img
                        src="/img/Home-Page/UP SKILLING.webp"
                        width="80"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <p className={Styles.card_head_features}>UPSKILLING</p>
                      <p className={Styles.card_subhead_features}>
                        1st - 2nd Years
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4 g-0">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/upskilling-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Get the HeadStart
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/upskilling-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Top Notch Fundamentals
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/upskilling-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Nurture into path for success
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/upskilling-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Learn from Elites in DSA and CP
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 px-lg-5 mt-5 mt-md-0">
              <div className={`card ${Styles.card_mod_features_intern}`}>
                <div className="card-body py-4">
                  <div className="row px-2">
                    <div className="col-3 my-auto">
                      <img
                        src="/img/Home-Page/INTERNSHIP.webp"
                        width="80"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <p className={Styles.card_head_features}>INTERNSHIP</p>
                      <p className={Styles.card_subhead_features}>
                        3rd - 4th Years
                      </p>
                    </div>
                  </div>
                  <div className="row mt-4 g-0">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/internship-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Separate Regime for Coding test and Interviews
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/internship-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Based on Recent trends of tests
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/internship-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Building an Ace Resume
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/internship-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Project Building
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 px-lg-5 mt-5">
              <div className={`card ${Styles.card_mod_features_placement}`}>
                <div className="card-body py-4">
                  <div className="row px-2">
                    <div className="col-3 my-auto">
                      <img
                        src="/img/Home-Page/PLACEMENT.webp"
                        width="80"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <p className={Styles.card_head_features}>PLACEMENT</p>
                      <p className={Styles.card_subhead_features}>Final Year</p>
                    </div>
                  </div>
                  <div className="row mt-4 g-0">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/placement-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Important Pointers on Core CS subjects
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/placement-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Access to PYQs of Big Companies
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/placement-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Specific Bootcamp for Interviews
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/placement-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Quant Preparation
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 px-lg-5 mt-5">
              <div className={`card ${Styles.card_mod_features}`}>
                <div className="card-body py-4">
                  <div className="row px-2">
                    <div className="col-3 my-auto">
                      <img
                        src="/img/Home-Page/SWITCH JOB.webp"
                        width="80"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="col-9 my-auto">
                      <p className={Styles.card_head_features}>JOB SWITCH</p>
                      <p className={Styles.card_subhead_features}>In any Job</p>
                    </div>
                  </div>
                  <div className="row mt-4 g-0">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/job-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Sessions after 7pm or on Weekends
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/job-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Recording available for Live Classes
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/job-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Vast mentor network for getting referrals
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mt-2">
                    <div className="col-1">
                      <img
                        src="/img/Home-Page/job-check-icon.svg"
                        className="img-fluid"
                        width="26"
                        alt=""
                      />
                    </div>
                    <div className="col-11 my-auto">
                      <p className={Styles.card_text_features}>
                        Regular contests to keep you in check
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center mt-5">
            <div className="col-12">
              <p className={Styles.features_ts_bottom}>
                Join to train like a champion in an ‘Out of Comfort’ challenging
                regime
              </p>
              <Link
                to="/premium/#curriculum"
                className={`btn ${Styles.btn_checkout} px-4 mt-2`}
              >
                Checkout For More Details
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className={`${Styles.mock_bg} mt-5`}>
        <div className="container">
          <div className="row">
            <div className={`col-md-4 text-start ${Styles.hide_sm}`}>
              <img
                src="/img/Home-Page/coding-interviews.webp"
                // width="300"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-12 col-lg-8 my-auto text-center text-lg-start">
              <p className={`${Styles.course_head} mt-5 mt-lg-0`}>
                Coding Test are different from Interviews
              </p>
              <img
                src="/img/Home-Page/coding-interviews.webp"
                width={200}
                className={`img-fluid ${Styles.hide_lg} py-2`}
                alt=""
              />
              <p className={`${Styles.course_subhead} pb-3`}>
                Practice
                <span className={Styles.mock_promo_ts}>
                  {" "}
                  50+ Companies
                </span>{" "}
                Previous Year questions curated at{" "}
                <span className={Styles.mock_promo_ts}>AlgoZenith Mock</span> !
              </p>
              <div className="d-flex justify-content-sm-center justify-content-lg-start pt-1 mb-5 mb-lg-0">
                <a
                  href={Urls.Practice.BaseUrl()}
                  className={`${Styles.btn_join_now} btn px-md-4`}
                >
                  Join Today
                </a>
                <button
                  className={`${Styles.Watch_btn} border-0 bg-transparent ms-5`}
                  data-bs-toggle="modal"
                  data-bs-target="#videoModal"
                  onClick={() => setVideo(mockVideo)}
                >
                  <img
                    src="/img/Home-Page/video-paly.svg"
                    className="img-fluid me-2"
                    width="40"
                    height="50"
                    alt=""
                  />
                  <span className={Styles.highlight}>Watch Video</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`py-5`}>
        <div className="container">
          <div className="row">
            <div className={`col-lg-3 text-center ${Styles.hide_sm}`}>
              <img
                src="/img/Home-Page/calendar.webp"
                className={`img-fluid ${Styles.cal_img}`}
                width={300}
                alt=""
              />
            </div>
            <div className="col-12 col-lg-9 my-auto pe-xl-5">
              <div className={`card ps-lg-5 ${Styles.newsletter_card_mod}`}>
                <div className="card-body ps-lg-5 text-center text-lg-start">
                  <p className={Styles.newletter_head}>
                    Sign up for our{" "}
                    <span className={Styles.newletter_innerhead}>
                      free weekly newsletter
                    </span>
                  </p>
                  <img
                    src="/img/Home-Page/calendar.webp"
                    className={`img-fluid ${Styles.hide_lg} ${Styles.cal_img}`}
                    width={150}
                    alt=""
                  />
                  <p className={Styles.newletter_subtext}>
                    want to receive free coding and interview preparation
                    resources daily, to bost your preparation?
                  </p>
                  <button
                    className={`btn ${Styles.btn_newsletter} btn-sm px-3 mt-2`}
                    id="subscribe-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#newslettermodal"
                  >
                    Subscribe Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`pt-5 ${Styles.form_bg}`}>
        <QueryForm />
      </section>

      <VideoModal video={video} />

      <div
        className="modal fade"
        id="newslettermodal"
        tabIndex="-1"
        aria-labelledby="newslettermodal"
        aria-hidden="true"
      >
        <div className="row">
          <div className="col-12 text-end">
            <button
              type="button"
              className="btn text-white text-end p-5"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className={`fa-solid fa-xmark ${Styles.closeSize}`}></i>
            </button>
          </div>
        </div>
        <div className={`modal-dialog modal-lg ${Styles.modal_adjust}`}>
          <div className="modal-content bg-transparent border-0">
            <script
              type="text/javascript"
              src="https://campaigns.zoho.in/js/zc.iframe.js"
            ></script>

            <iframe
              frameBorder="0"
              id="iframewin"
              loading="lazy"
              width="100%"
              height="550"
              src="https://jxvp-zc1.maillist-manage.in/ua/Optin?od=1a1e3db33e7ae&zx=1df8be952b&sD=11b087578ad3c0a1"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;

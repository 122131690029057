import { Button } from "antd";
import { Urls } from "constants/Urls";
import { FaBookOpen, FaCode, FaLaptopCode } from "react-icons/fa";
import { SiGooglescholar } from "react-icons/si";
import { VscClose } from "react-icons/vsc";
import Styles from "../HomePage.module.css";
import Modal from "./Modal";

const RecentHighlight = ({ setShowPremiumModal }) => {
  return (
    <Modal>
      <div
        className={`${Styles.hidescrollbar} w-100 h-100 position-fixed d-flex align-items-start justify-content-center hide-scrollbar pt-5 mt-5`}
        onClick={() => setShowPremiumModal(false)}
        style={{
          zIndex: "100",
          top: "0",
          left: "0",
          backgroundColor: "#172B4DCC",
          backdropFilter: "blur(8px)",
          overflowY: "auto",
        }}
      >
        <section
          className={`dmsans overflow-hidden p-4 mt-5 `}
          style={{ maxWidth: "72rem" }}
        >
          <div
            className="bg-white border_primary border_radius_12 d-flex flex-column "
            onClick={(e) => e.stopPropagation()}
            style={{ borderRadius: "12px", border: "10px solid white" }}
          >
            <div
              className="d-flex  justify-content-between flex-column p-4 text-white position-relative"
              style={{
                background: "#1A2857",
                borderRadius: "10px",
              }}
            >
              <div className="d-flex justify-content-center mb-4 align-items-center">
                <h1 className="fs-3 mb-0 fw-bold text-center text-white">
                  RECENT HIGHLIGHTS
                </h1>
                <Button
                  className="d-flex align-items-center justify-content-center text-white position-absolute"
                  onClick={() => setShowPremiumModal(false)}
                  type="text"
                  style={{ right: "20px", top: "20px" }}
                  icon={<VscClose className="fs-3" />}
                ></Button>
              </div>
              <p className="text-center">
                We've successfully transitioned to our new online platform,
                &nbsp;
                <Button
                  type="link"
                  href={Urls.Main.MaangInUrl()}
                  className="text-white p-0 fs-6 m-0"
                >
                  <span style={{ textDecoration: "underline" }}>maang.in!</span>
                </Button>{" "}
                Explore our upgraded website for an enhanced user experience and
                exciting features.
              </p>
              <div className="text-center d-flex flex-column flex-lg-row justify-content-center align-items-center  flex-grow-1 gap-4 mt-2 ">
                <div
                  className="flex-grow-1 overflow-hidden"
                  style={{ borderRadius: "10px", maxWidth: "44rem" }}
                >
                  <img
                    className="w-100 h-100"
                    style={{}}
                    src="/img/Home-Page/recent.png"
                    alt="AlgoZenith"
                  />
                </div>
                <div
                  className={`${Styles.recent_button_container} d-flex flex-column gap-4 `}
                  style={{ minWidth: "20rem" }}
                >
                  <div className="d-flex flex-column  mb-2">
                    <p
                      className={`${Styles.recent_highlight_text} mb-3`}
                    >{`if(!knowAlgoZenith) { viewCourse(); }`}</p>
                    <Button
                      className={`${Styles.view_course_button} text-decoration-none mx-3`}
                      type="link"
                      href={Urls.Main.NewCoursePageUrl()}
                    >
                      <FaLaptopCode style={{ fontSize: "1.1rem" }} />
                      View Course
                    </Button>
                  </div>
                  <div className="d-flex flex-column  mb-2">
                    <p
                      className={`${Styles.recent_highlight_text} mb-3`}
                    >{`if(BRAIN!=empty) { keepCoding(); }`}</p>
                    <Button
                      className={`${Styles.start_learn_button} text-decoration-none mx-3`}
                      type="link"
                      href={Urls.Main.NewCoursePageUrl()}
                    >
                      <FaBookOpen style={{ fontSize: "1.1rem" }} />
                      Start Learning
                    </Button>
                  </div>
                  <div className="d-flex flex-column  mb-2">
                    <p
                      className={`${Styles.recent_highlight_text} mb-3`}
                    >{`while(noSucess) { tryAgain(); }`}</p>
                    <Button
                      className={`${Styles.mock_test_button} text-decoration-none mx-3`}
                      type="link"
                      href={Urls.Main.NewMockPageUrl()}
                    >
                      <FaCode style={{ fontSize: "1.1rem" }} />
                      Take MOCK Test
                    </Button>
                  </div>
                  <div className="d-flex flex-column  mb-2">
                    <p
                      className={`${Styles.recent_highlight_text} mb-3`}
                    >{`SELECT * from TESTIMONIALS;`}</p>
                    <Button
                      className={`${Styles.testimonial_button} text-decoration-none mx-3`}
                      type="link"
                      href={Urls.Main.ResultsPageUrl()}
                    >
                      <SiGooglescholar style={{ fontSize: "1.1rem" }} />
                      View Testimonials
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default RecentHighlight;

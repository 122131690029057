import ReactDOM from "react-dom/client";
import ReactPixel from "react-facebook-pixel";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./ReportWebVitals";
import ScrollToTop from "./helpers/ScrollToTop";
import "./index.css";

ReactPixel.init("932949537449754");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
);

reportWebVitals();

import React from "react";
import { Link } from "react-router-dom";
import { Urls } from "../../../constants/Urls";
import Styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={`container-fluid ${Styles.footer_color}`}>
      <div className={`row py-4 px-4 `}>
        <div className="col-12 col-md-6 col-lg-3 text-center text-md-start">
          {/* <p className={`${Styles.links_head}`}>ALGOZENITH</p> */}
          <img
            src="/img/Nav-Bar/AZ-white-logo.svg"
            className="img-fluid"
            width={160}
            alt=""
          />
          <p className="mt-4">
            Plot No 365, Om Nagar, Behind Power House, <br /> Nagpur,
            Maharashtra 440009, India
          </p>
        </div>
        <div
          className={`col-12 col-md-6 col-lg-3 text-center text-md-start mt-5 mt-md-0 ps-lg-5 ${Styles.footer_links}`}
        >
          <p className={`${Styles.links_head}`}>ALGOZENITH</p>
          <p>
            <Link
              className={Styles.footer_links}
              to={"/premium"}
              aria-label="Algozenith Premium"
            >
              Algozenith Premium
            </Link>
          </p>
          <p>
            <Link className={Styles.footer_links} to={"/faq"} aria-label="FAQs">
              FAQs
            </Link>
          </p>
          <p>
            <Link
              className={Styles.footer_links}
              to={"/results"}
              aria-label="Results"
            >
              Results
            </Link>
          </p>
          <p>
            <a
              href={Urls.Practice.BaseUrl()}
              className={Styles.footer_links}
              aria-label="Mock"
            >
              Mock
            </a>
          </p>
        </div>
        <div
          className={`col-12 col-md-6 col-lg-3 text-center text-md-start mt-5 mt-md-0 ${Styles.footer_links}`}
        >
          <p className={`${Styles.links_head}`}>USEFUL LINKS</p>
          <p>
            <a
              className={Styles.footer_links}
              href={Urls.Social.MediumUrl()}
              aria-label="Blogs"
            >
              Blogs
            </a>
          </p>
          <p>
            <a
              className={Styles.footer_links}
              href={Urls.Legal.PrivacyPolicyUrl()}
              aria-label="Privacy Policy"
            >
              Privacy Policy
            </a>
          </p>
          <p>
            <a
              className={Styles.footer_links}
              href={Urls.Legal.TermsAndConditionsUrl()}
              aria-label="Terms & Conditions"
            >
              Terms & Conditions
            </a>
          </p>
          <p>
            <a
              className={Styles.footer_links}
              href={Urls.Legal.PriceAndRefundPolicyUrl()}
              aria-label="Price & Refund Policy"
            >
              Price & Refund Policy
            </a>
          </p>
          {/* <a className={Styles.footer_links} href="">
            Bug Bounty
          </a> */}
        </div>
        <div className="col-12 col-md-6 col-lg-3 text-center text-md-start mt-5 mt-md-0">
          <p className={`${Styles.links_head}`}>FOLLOW US ON</p>
          <div className="d-flex justify-content-center justify-content-md-start mt-4">
            <a
              className={Styles.icons_mod}
              href={Urls.Social.FacebookUrl()}
              aria-label="Facebook"
            >
              <i className="fa-brands fa-square-facebook me-3"></i>
            </a>
            <a
              className={Styles.icons_mod}
              href={Urls.Social.YoutubeUrl()}
              aria-label="Youtube"
            >
              <i className="fa-brands fa-youtube ms-5"></i>
            </a>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start mt-5">
            <a
              className={Styles.icons_mod}
              href={Urls.Social.InstagramUrl()}
              aria-label="Instagram"
            >
              <i className="fa-brands fa-instagram me-3"></i>
            </a>
            <a
              className={Styles.icons_mod}
              href={Urls.Social.LinkedInUrl()}
              aria-label="Linkedin"
            >
              <i className="fa-brands fa-linkedin-in ms-5"></i>
            </a>
          </div>
        </div>
      </div>

      <div className={`row ${Styles.copy} text-center pt-3`}>
        <div className="col-12">
          <p>
            &#169; Copyright 2023. AlgoZenith Technologies Pvt Ltd. All Rights
            Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import AdvertisementBar from "components/AdvertisementBar/AdvertisementBar";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import { Urls } from "../../../constants/Urls";
import AuthService from "../../../services/AuthService";
import classes from "./Header.module.css";

const NavBar = () => {
  let activeStyle = {
    color: "#05445e",
    fontWeight: "600",
    borderBottom: "2px solid #189ab4",
  };

  const collapseRef = useRef(null);

  const hideBars = () => {
    collapseRef.current.setAttribute("class", "navbar-collapse collapse");
  };

  return (
    <div className="sticky-top">
      <AdvertisementBar />
      <nav className={`navbar navbar-expand-lg navbar-light  bg-light`}>
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/home">
            <img
              src="/img/Nav-Bar/AZ-logo.svg"
              className="img-fluid"
              alt="logo"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarScroll"
            ref={collapseRef}
          >
            <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
              <li className={`nav-item ${classes.navbar_ts} px-1`}>
                <NavLink
                  to="/home"
                  className="nav-link"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  aria-current="page"
                  onClick={hideBars}
                >
                  Home
                </NavLink>
              </li>
              <li className={`nav-item ${classes.navbar_ts}  px-1`}>
                <NavLink
                  to="/premium"
                  className="nav-link"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  onClick={hideBars}
                >
                  Premium
                </NavLink>
              </li>
              {/* <li className={`nav-item ${classes.navbar_ts} px-1`}>
                <NavLink to={"/dev"} className="nav-link" onClick={hideBars}>
                  Dev
                  <img
                    src="/img/workshop/new-svg.svg"
                    className="img-fluid"
                    alt=""
                    width={"25px"}
                  />
                </NavLink>
              </li> */}
              <li className={`nav-item ${classes.navbar_ts} px-1`}>
                <NavLink
                  to={"/webinars"}
                  className="nav-link"
                  onClick={hideBars}
                >
                  Webinars
                  <img
                    src="/img/workshop/free.svg"
                    className="img-fluid"
                    alt=""
                    width={"25px"}
                  />
                </NavLink>
              </li>
              <li className={`nav-item ${classes.navbar_ts} px-1`}>
                <NavLink
                  to={"/results"}
                  className="nav-link"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  onClick={hideBars}
                >
                  Results
                </NavLink>
              </li>
              <li className={`nav-item ${classes.navbar_ts} px-1`}>
                <NavLink
                  to={"/faq"}
                  className="nav-link"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  onClick={hideBars}
                >
                  FAQs
                </NavLink>
              </li>
              <li className={`nav-item ${classes.navbar_ts} px-1`}>
                <a
                  href={Urls.Practice.BaseUrl()}
                  className="nav-link"
                  onClick={hideBars}
                >
                  Mock
                </a>
              </li>

              {AuthService.LoggedIn() && (
                <li className={`nav-item ${classes.navbar_ts} px-1`}>
                  <a
                    href={Urls.Learning.DashboardUrl()}
                    className="nav-link"
                    onClick={hideBars}
                  >
                    My Courses
                  </a>
                </li>
              )}
              {!AuthService.LoggedIn() && (
                <li className={`nav-item ${classes.navbar_ts}  px-lg-2`}>
                  <a
                    href={Urls.Learning.RegisterUrl()}
                    className={`btn ${classes.btn_red} px-3`}
                  >
                    Sign Up
                  </a>
                </li>
              )}
              {!AuthService.LoggedIn() && (
                <li
                  className={`nav-item ${classes.navbar_ts}  mt-3 mt-lg-0 px-lg-2`}
                >
                  <a
                    href={Urls.Learning.LoginUrl()}
                    className={`btn ${classes.btn_bordered} px-4`}
                  >
                    Login
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;

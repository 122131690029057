import React from "react";
import { ClimbingBoxLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="container">
      <div className="row py-5 my-5">
        <div className="col-12 d-flex aligns-items-center justify-content-center py-5 my-5">
          <ClimbingBoxLoader color="#29539B" />
        </div>
      </div>
    </div>
  );
};

export default Loader;

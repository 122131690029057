import { Button } from "antd";
import { Urls } from "constants/Urls";
import { useSearchParams } from "react-router-dom";
import Styles from "./AdvertisementBar.module.css";

const AdvertisementBar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const invitekey = searchParams.get("invitekey") ?? "";
  const couponcode = searchParams.get("couponcode") ?? "";

  return (
    <>
      <div
        className={`alert alert-warning alert-dismissible fade show text-center mb-0 p-2 overflow-hidden ps-xl-5 ${Styles.Ad_bar_ts}`}
        role="alert"
      >
        <div className="row">
          <div className="col-11">
            <div>
              <span className={`${Styles.emoji} pe-2`}>🎉</span>We're now at
              &nbsp;
              <Button
                className="fs-5 p-0 m-0 fw-bold"
                type="link"
                style={{ color: "#664D04" }}
                href={Urls.Main.MaangInUrl()}
              >
                <span style={{ textDecoration: "underline" }}>maang.in!</span>
              </Button>{" "}
              Algozenith Premium Course starting from{" "}
              <strong className={Styles.red}>
                02<sup>nd</sup> March. Hurry!!
              </strong>
              <Button
                type="text"
                href={Urls.Main.NewPremiumCourseUrl()}
                className={`btn btn-danger btn-sm px-3 ms-3 rounded-pill ${Styles.enroll_btn}`}
              >
                Visit Now
              </Button>
            </div>
            <div></div>
          </div>
          <div className="col-1">
            <button
              type="button"
              className="btn-close btn-sm"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvertisementBar;

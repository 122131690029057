export const getFromLocalStorage = (key) => {
  if (typeof window == "undefined") {
    return;
  }

  const localStorage = window.localStorage;
  const value = localStorage.getItem(key);
  if (!value) return null;
  return JSON.parse(value);
};

export const storeToLocalStorage = (key, data) => {
  const localStorage = window.localStorage;
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (err) {}
};

export const removeFromLocalStorage = (key) => {
  const localStorage = window.localStorage;
  return localStorage.removeItem(key);
};

import {
  getFromLocalStorage,
  storeToLocalStorage,
} from "./LocalStorageService";

export const getIsRecentHighlightOpen = () => {
  const value = getFromLocalStorage("recent-highlight-last-open");
  return value == null ? false : value;
};

export const storeIsRecentHighlightOpen = (value) => {
  storeToLocalStorage("recent-highlight-last-open", value);
};
